export default class UserSessionFunctions {
    static setUserSesssionMsisdn(msisdn) {
        sessionStorage.setItem('msisdn', msisdn)
    }

    static setUserSesssionHasLoggedIn(hasLoggedIn) {
        sessionStorage.setItem('hasLoggedIn', hasLoggedIn)
    }

    static getUserSesssionMsisdn() {
       return sessionStorage.getItem('msisdn')
    }

    static getUserSesssionHasLoggedIn() {
       return sessionStorage.getItem('hasLoggedIn')
    }
}
