<template>
  <div class="d-flex flex-column align-items-center">
    <LoadingOverlay v-if="isLoading"/>
    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 vue-container safari-toolbars-hidden">
      <router-view />
    </div>
  </div>
</template>

<script type="text/javascript">
import LoadingOverlay from "@/views/components/LoadingOverlay";
import {mapState} from "vuex";

export default {
  components: {
    LoadingOverlay
  },
  computed: {
    ...mapState(['isLoading'])
  },
  methods: {
    setFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = require(`./assets/logo.png`)
    },
  },
  beforeMount() {
    this.setFavicon()
  }
}

</script>

<style>
@font-face {
  font-family: 'mtn-regular';
  src: url('~@/assets/fonts/MTNBrighterSans-Regular.ttf');
}

@font-face {
  font-family: 'mtn-bold';
  src: url('~@/assets/fonts/MTNBrighterSans-Bold.ttf');
}

@font-face {
  font-family: 'mtn-ittalic';
  src: url('~@/assets/fonts/MTNBrighterSans-RegularItalic.ttf');
}

.vue-container {
  background-size: cover;
  height: 100vh;
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  overflow-x: hidden;
}

@supports (-webkit-overflow-scrolling: touch) {
  .safari-toolbars-hidden {
    bottom: 44px;
  }
}

</style>
