import {createStore} from 'vuex';

export default createStore({
    state: {
        user: undefined,
        showOutOfPlaysPopup: false,
        broadcastedGameState: undefined,
        isLoading: false,
        showMonthlyDraw: false,
        showPrizeInformation: false,
        showClaimPrizes: false,
        lastGameId: 1325751
    },
    mutations: {
        setShowClaimPrizes(state, showClaimPrizes) {
            state.showClaimPrizes = showClaimPrizes
        },
        setLastGameId(state, lastGameId) {
            state.lastGameId = lastGameId
        },
        setShowPrizeInformation(state, showPrizeInformation) {
            state.showPrizeInformation = showPrizeInformation
        },
        setShowMonthlyDraw(state, showMonthlyDraw) {
            state.showMonthlyDraw = showMonthlyDraw
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        setBroadcastedGameState(state, broadcastedGameState) {
            state.broadcastedGameState = broadcastedGameState;
        },
        setUser(state, user) {
            state.user = user
        },
        setShowOutOfPlaysPopup(state, showOutOfPlays) {
            state.showOutOfPlaysPopup = showOutOfPlays;
        },
    },
    actions: {},
    modules: {}
})
